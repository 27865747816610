.footer {
  padding: 0 1rem 0 0;
  width: 100%;
  border-radius: 6px;
  background: #fab383;
}

.footer_sub {
  flex-direction: row;
  flex-wrap: wrap;
  display: block;
  position: relative;
}
.contact_phone {
  padding: 10px;
}

@media screen and (max-width: 850px) {
  .footer {
    padding: 4rem 2rem;
  }
  .footer_sub {
    margin: 1rem 1rem 0 0;
  }
  .email {
    font-size: small;
  }
}
