.feedbacks-page {
  /* max-width: 800px; */
  margin: 0 auto;
  text-align: center;
}

.feedbacks-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}

.feedback {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 40px;
  text-align: left;
}

.feedback img {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.feedback-text {
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
}

.feedback-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.quote-icon {
  width: 2rem;
  height: 2rem;
}
