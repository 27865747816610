.serviceslist {
  margin: 2rem 4rem;
  color: #2a2a2a;
}

.serviceslist h1 {
  font-size: 4rem;
}

.servicecard {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.servicecard-last {
  display: flex;
  margin: 0;
  justify-content: left;
}

.create-even-space {
  padding-left: 25px;
}

.create-even-space-last {
  padding-top: 50px;
}

.servicecard-last h4 {
  font-size: 1.4rem;
  padding: 0.9rem 0 0.2rem 0;
  text-align: left;
}

.servicecard h4 {
  font-size: 1.4rem;
  padding: 0.9rem 0 0.2rem 0;
  text-align: left;
}

.s-card {
  width: 32%;
  text-align: left;
  box-shadow: 1px 8px 4px rgba(58, 56, 56, 0.2);
  border-radius: 7px;
  cursor: pointer;
  padding: 1rem 0.8rem;
}

.s-card p {
  font-size: 15px;
}
.s-card:hover {
  background-color: white;
  transition: 0.3s ease-in-out;
}

.s-image {
  height: 200px;
  overflow: hidden;
  border-radius: 7px;
}

.s-image img {
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
}

.s-image:hover img {
  transform: scale(1);
}

@media screen and (max-width: 850px) {
  .serviceslist {
    margin: 1rem 1rem;
  }

  .serviceslist h1 {
    font-size: 2rem;
  }
  .serviceslist h4 {
    font-size: 1.1rem;
  }
  .servicecard {
    flex-direction: column;
    margin-top: 0;
  }

  .s-card {
    width: 100%;
    margin-bottom: 1.5rem;
    padding: 0;
  }

  .servicecard-last {
    flex-direction: column;
  }
  .s-image img {
    width: 100%;
    height: 100%;
  }
  .create-even-space-last {
    padding-top: 0px;
  }
}
