.faq-container {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  padding: 0 10rem 0;
}

.faq-item {
  margin-bottom: 1rem;
}

.faq-question {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  color: #333;
}

.faq-question.active {
  color: #0074d9;
}

.faq-answer {
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
  margin-bottom: 1rem;
  max-width: 40rem;
  text-align: justify;
  display: none;
}

.faq-answer.active {
  display: block;
}
@media screen and (max-width: 850px) {
  .faq-container {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
}
