.blog-page {
  /* max-width: 800px; */
  margin: 0 auto;
  display: grid;
  gap: 30px;
  text-align: left;
}

.blog-post {
  margin-top: 20px;
  padding: 20px;
  /* background-color: #f9f9f9; */
  border-radius: 10px;
  box-shadow: 1px 8px 4px rgba(0, 0, 0, 0.2);
}

.blog-post h2 {
  margin-top: 0;
}

.blog-post-date {
  font-style: italic;
  margin-bottom: 10px;
}

.blog-post p {
  margin-bottom: 20px;
}

.blog-post a {
  color: #333;
  text-decoration: none;
  border-bottom: 1px dotted #333;
}

.blog-post a:hover {
  border-bottom-style: solid;
}

@media screen and (max-width: 850px) {

  h2 {
    font-size: 15px;
  }
  p{
    font-size: 7.5px;
  }
}