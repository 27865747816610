.top-text h2 {
  text-align: right;
}

.top {
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: right;
  flex-wrap: wrap;
}

.top i {
  font-size: 2rem;
  margin-left: 1rem;
  box-shadow: 1px 8px 4px rgba(248, 246, 246, 0.2);
}

.top i:hover {
  color: #81959a;
}

@media screen and (max-width: 850px) {
  .footer {
    padding: 4rem 2rem;
  }
  .footer i {
    margin: 1rem 1rem 0 0;
  }
}
