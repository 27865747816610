.hero {
  width: 100%;
  height: 50vh;
  position: inherit;
}

.hero-mid {
  width: 100%;
  height: 70vh;
  position: relative;
}
.heroImage {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.hero-text {
  position: inherit;
  transform: translate(10%, 7%);
}

.hero-text h1 {
  font-size: 3rem;
  font-weight: 800;
  background: rgb(42, 34, 24);
  /* background-clip:inherit; */
  -webkit-background-clip: text;
  /* background-clip: border-box; */
  -webkit-text-fill-color: transparent;
}

.hero-mid h1 {
  padding-top: 4 rem !important;
}

.hero-text p {
  font-size: 1.3rem;
  color: aquamarine;
  padding: 0.5rem 2rem 1rem 0.2rem;
}

.hero-text .btnClass {
  text-decoration: none;
  background: #fff;
  padding: 0.4rem 2rem;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: black;
}

.hero-text .hide {
  display: none;
}
@media screen and (max-width: 850px) {
  .home {
    height: 100vh;
  }
  .hero {
    width: 100%;
    position: inherit;
    height: auto;
  }
  .hero-text h1 {
    padding: 0px;
  }
  .hero-text p {
    font-size: 1.1rem;
    padding: 0 0 2rem 0;
  }

  .hero-text .btnClass {
    padding: 0.6rem 1.1rem;
    border-radius: 6px;
    font-size: 1rem;
    background: rgb(16, 181, 210);
  }
  .heroImage {
    height: 75%;
  }
}
