/* Default styles */
main {
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  display: inline-flex;
}
section {
  flex-basis: 30%;
  margin-bottom: 2rem;
  text-align: center;
}

section h1 {
  flex-basis: 30%;
  margin-bottom: 2rem;
  text-align: center;
}

.about-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 30px;
  text-align: left;
}

.about-grid p {
  padding: 20px;
  background-color: #fab383;
  border: 1px solid #ddd;
  padding: 20px;
  display: inherit;
}

.quote-icon {
  width: 2rem;
  height: 2rem;
}
/* Styles for screens smaller than 768px */
@media (max-width: 767px) {
  section {
    flex-basis: 100%;
  }
}

/* Styles for screens between 768px and 1200px */
@media (min-width: 768px) and (max-width: 1200px) {
  section {
    flex-basis: 45%;
  }
  .about-grid {
    display: block;
    text-align: left;
  }
}
@media (max-width: 768px) {
  section {
    flex-basis: 45%;
  }
  .about-grid {
    display: block;
    text-align: left;
    gap: 10px;
  }
}
