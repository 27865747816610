.myComponent {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 40px 0 0 0;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: flex-end; */
  overflow: hidden;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

h1 {
  margin-bottom: 10px;
  font-family: Impact, Charcoal, sans-serif;
  font-size: 60px;
  text-transform: uppercase;
  color: #135e53;
  /* background-image: url("https://dignitynchoicecare.com.au/static/media/Logo.0575be06c47b639bb67b.jpeg");  */
  background-clip: text;
  -webkit-background-clip: text;
  /* -webkit-text-fill-color:transparent; */
  text-shadow: 0px 2px 2px #070800ac;
}

button {
  background-color: #bad2ea;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
}

button:hover {
  background-color: #2a4153;
}

@media screen and (max-width: 850px) {
  .myComponent {
    display: inline;
  }

  p {
    font-size: small;
  }
  h1 {
    font-size: 30px;
  }
  button{
    font-size: 10px;
  }
}