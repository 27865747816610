.resources-container {
  width: 100%;
  /* max-width: 1000px; */
  margin: 0 auto;
  padding: 40px 20px;
  box-shadow: 1px 8px 4px rgba(0, 0, 0, 0.2);
}

.resources-heading {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 40px;
}

.resources-list {
  list-style: none;
  padding: 0;
}

.resources-item {
  margin-bottom: 40px;
  margin: 10px;
}

.resource-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.resource-title svg {
  margin-right: 10px;
  color: #007bff;
}

.resource-description {
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.resource-link {
  font-size: 1.2rem;
}

.resource-link a {
  color: #007bff;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.resource-link a svg {
  margin-left: 5px;
}
