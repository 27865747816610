.news-container {
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 40px 0;
}

.news-container h1 {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  gap: 30px;
}

.news-item {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
}

.news-item a {
  color: inherit;
  text-decoration: none;
}

.news-item img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.news-item h2 {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
  margin-bottom: 10px;
}

.news-item .news-date {
  font-size: 0.9rem;
  font-weight: 400;
  color: #666;
  margin: 0;
  margin-bottom: 10px;
}

.news-item .news-summary {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  margin: 0;
  margin-bottom: 10px;
}

.news-item .news-author {
  font-size: 0.9rem;
  font-weight: 400;
  color: #999;
  margin: 0;
}

@media screen and (max-width: 850px) {
  .news-item img {
    width: 50%;
    height: auto;
  }
  .news-grid {
    display: inline;
    align-items: center;
  }
  .news-item {
    padding: 10px;
  }
}
