@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,100;1,200;1,400;1,500;1,600;1,900&display=swap");

.dark {
  background: #fedeca;
  color: black;
}

.dark-mode {
  background: black;
  color: white;
  padding-top: 20px;
  /* text-align: center; */
}
.dark-mode_footer {
  background: black;
  color: white;
  padding-top: 20px;
  text-align: center;
}

.darkfooter {
  text-align: center;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.box1 {
  padding: 20px 0 0 20px;
  border: 0px solid black;
  margin-bottom: 5px;
  margin-top: 5px;
}

.box3 {
  border: 0px solid black;
  height: auto;
  padding: 20px;
  margin-top: 5px;
}
.box4 {
  margin-bottom: 5px;
  padding: 20px;
  border: 0px solid black;
  height: auto;
}
.box5 {
  padding: 20px;
  border: 0px solid black;
  height: auto;
}

.box6 {
  padding: 20px;
  border: 0px solid black;
  height: auto;
}

.box7 {
  padding: 20px;
  border: 0px solid black;
  height: auto;
}
.box8 {
  border: 0px solid black;
  text-align: center;
  flex-wrap: wrap;
  background-color: #fab383;
}

.btnClass {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  margin-right: auto;
}

.timeStamp {
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: right;
  flex-wrap: wrap;
  margin-left: auto;
}

.darkmodeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
