.NavbarItems {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.6rem;
  padding: 0 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 25);
  width: 95%;
  height: 80px;
  border-radius: 13px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
}

.navbar-logo {
  cursor: pointer;
}

.nav_website {
  text-decoration: none;
  font-size: 0.7rem;
  line-height: 10px;
  color: black;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-gap: 0.1px;
  list-style: none;
  align-items: center;
  text-align: center;
  justify-content: end;
}
.nav-menu:hover {
  transition: 0.3s ease-in-out;
  transform: scale(1);
}

.nav-links {
  text-decoration: none;
  color: #222;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.7rem 1rem;
  white-space: nowrap;
}

.nav-links i {
  padding-right: 10px;
}

.nav-links:hover {
  background-color: #27aac4;
  color: #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.fa-bars,
.fa-times {
  color: blue;
}

.menu-icons {
  display: none;
}

.nav-links-mobile {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  background-color: #b0c0c1;
  border-radius: 3px;
  text-transform: none;
  text-decoration: none;
  font-size: 0.7rem;
  font-weight: 500;
  color: #222;
}

@media screen and (max-width: 850px) {
  .NavbarItems {
    z-index: 99;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    backdrop-filter: blur(4px);
    border-radius: 13px;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -110%;
    opacity: 1;
    align-items: stretch;
    padding: 80px 0 30px 0;
    margin: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }

  .nav-menu.active {
    left: 0%;
  }

  .nav-links {
    display: block;
    widows: 100%;
    padding: 2rem 0;
    color: #222;
  }

  .nav-links:hover {
    background-color: #27aac4;
    transition: none;
    backdrop-filter: blur(20px);
  }
  .menu-icons {
    display: block;
  }

  .nav-links-mobile {
    padding: 1.5rem;
    display: block;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 700;
    border-radius: 4px;
    background-color: #011014;
    color: white;
    width: 80%;
    margin: auto;
    letter-spacing: 2px;
  }
}
